/***************************************************************
 * @NOTE: 医療機関詳細暫定移植で追加したファイルのため正規対応時に整理予定
 ***************************************************************/
/* eslint no-undef: 0 */
$(function () {
  const windowHeight = $(window).height();
  // メディカルノートのアプリの案内の画像の位置
  if (!$('.js-app_introduction').get(0)) { return; }
  const triggerPosition = $('.js-app_introduction').offset().top - windowHeight;

  // 下部の固定ボタン(ネット予約 or オンライン診療 or 公式サイトへの動線)
  if (!$('.js-btn_fixed__wrap').get(0)) { return; }
  const bottomButton = $('.js-btn_fixed__wrap');

  // 上から下にスクロールしていってアプリの案内が画面内に入る → 下部の固定ボタンを非表示
  // 下から上にスクロールしていってアプリの案内が画面外に出る → 下部の固定ボタンを表示
  $(window).scroll(function () {
    const scroll = $(window).scrollTop();
    if (scroll > triggerPosition) {
      bottomButton.fadeOut(300);
    } else {
      bottomButton.fadeIn(300);
    }
  });
});
